import React from "react"
import Video from "../components/Video"
import VideoMobile from "../components/VideoMobile"

export default function DieselPage(props) {
  return (
    <div id="diesel-smartwatch">
      <VideoMobile
        title="Diesel, T-ON-I Smartwatch • Directed by Arnaud Bresson"
        src="https://player.vimeo.com/video/280697243?title=0&muted=0"
        allowsInlineMediaPlayback="true"
      />
      <Video
        title="Diesel, T-ON-I Smartwatch • Directed by Arnaud Bresson"
        src="https://player.vimeo.com/video/280697243?background=1&autoplay=1&loop=1&byline=0&title=0&muted=0"
      />
    </div>
  )
}
